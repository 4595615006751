import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from './components/Card'

const RecommendedList = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing};
`
const RecommendedItem = styled.li`
  line-height: 28px;
  margin-bottom: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColorLight};
`
const RecommendedCard = props => {
  const { card } = props
  const { title } = card.data
  return (
    <Card type={card.data.type || card.type} title={title}>
      <RecommendedList>
        {card.data.values.map(item => (
          <RecommendedItem key={`type-${item}`}>{item}</RecommendedItem>
        ))}
      </RecommendedList>
    </Card>
  )
}

RecommendedCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
}

export default RecommendedCard
