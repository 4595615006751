import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled, { withTheme } from 'styled-components'
import { AreaChart, Area, XAxis, Label, ResponsiveContainer, Legend } from 'recharts'

import { renderSquaredLegend as renderLegend } from './Legend'
import { withTranslation } from '../../../../i18n'

const Wrapper = styled.div`
  padding: 0.5em;
  margin-top: 20px;
`
const ageRange = {
  '0-5': 6,
  '6-12': 7,
  '13-15': 3,
  '16-18': 3,
  '19-23': 5,
  '24-34': 11,
  '35-44': 10,
  '45-54': 10,
  '55-64': 10,
  '65-74': 10,
  '75-84': 10,
  '85+': 6,
}

const DPAreaChart = React.memo(({ data, type, nabolag, city, category, theme, t }) => {
  const valueData = data.values || data
  const valueKey = valueData[0].percent.neighborhood ? 'neighborhood' : 'census'
  const compareKey = valueData[0].percent.city ? 'city' : 'municipality'
  const chartData = []
  for (let i = 0; i < valueData.length; i += 1) {
    const { group } = valueData[i]
    const value = valueData[i].percent[valueKey]
    const compareValue = valueData[i].percent[compareKey]
    const nextValue = valueData[i + 1] ? valueData[i + 1].percent[valueKey] : null
    const nextCompare = valueData[i + 1] ? valueData[i + 1].percent[compareKey] : null
    const interval = nextValue - value
    const compareInterval = nextCompare - compareValue
    const range = ageRange[group]
    const stepValue = interval / range
    const compareStepValue = compareInterval / range
    // Add first point
    chartData.push({
      name: group,
      value,
      compareValue,
    })
    /*
      If a next value exists add steps to chart to get
      a proper transition relative to the range of the group
    */
    if (nextValue) {
      for (let j = 1; j < range; j += 1) {
        chartData.push({
          name: `${group}-${j}`,
          value: value + stepValue * j,
          compareValue: compareValue + compareStepValue * j,
        })
      }
    }
  }
  return (
    <Wrapper>
      <ResponsiveContainer width="99%" height={225}>
        <AreaChart
          data={chartData}
          margin={{
            top: 5,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Area
            type="linear"
            stroke={theme.areaChartColors.mainColor}
            fill={theme.areaChartColors.mainColor}
            dataKey="compareValue"
            fillOpacity={theme.areaChartColors.fillOpacity}
          />
          <Area
            type="linear"
            dataKey="value"
            stroke={theme.areaChartColors.comparisonColor}
            strokeWidth={5}
            fill="transparent"
          />
          <Legend content={renderLegend} nabolag={nabolag} city={city} />
          <XAxis tick={false} axisLine={false}>
            <Label position="insideLeft" fill={theme.areaChartColors.labelColor}>
              {t('charts:children')}
            </Label>
            <Label position="center" fill={theme.areaChartColors.labelColor}>
              {t('charts:adults')}
            </Label>
            <Label position="insideRight" fill={theme.areaChartColors.labelColor}>
              {t('charts:older')}
            </Label>
          </XAxis>
        </AreaChart>
      </ResponsiveContainer>
    </Wrapper>
  )
})

export default compose(
  withTranslation([
    'common',
    'charts',
    'categories',
    'family',
    'transport',
    'leisure',
    'shopping',
    'environment',
    'people',
  ]),
  withTheme,
)(DPAreaChart)
