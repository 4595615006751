/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import { compose } from 'recompose'
import smoothscroll from 'smoothscroll-polyfill'
import { connect } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

import { getShowCardListArrowsOnMobile } from '../store/nabolag/selectors'
import { H2 } from '../ui/Headings'
import {
  BaselineArrowForward as ArrowForwardIcon,
  BaselineArrowBack as ArrowBackIcon,
} from './icons'
import { toggleDialog } from '../store/ui/actions'
import cardTypes from '../models/CardTypes'
import { trackEvent } from '../utils/analytics'
import TravelRouteCardContainer from './travel-route-card-container'
import {
  Age,
  Compare,
  Poi,
  Rating,
  Recommended,
  Quote,
  SunCurve,
  Image,
  SkiTracks,
  Alpine,
} from './card'
import media from './layout/media'
import Anchor from './anchor'
import { withTranslation } from '../../i18n'

const SubTitle = styled.div`
  display: block;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.fontColorLight};
  padding-bottom: 20px; // 20px from the heading
`

const SubTitleItem = styled.div`
  &:first-child {
    margin-top: 15px;
  }
`

const CardWrapper = styled.div`
  position: relative;
  margin-top: 45px;
  margin-bottom: 45px;
  box-sizing: border-box;
  background-clip: padding-box;

  ${({ removeTopMargin }) =>
    removeTopMargin &&
    css`
      margin-top: 0;
    `};
`

const CardBorder = styled.div`
  display: block;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  margin-left: ${props => props.theme.spacingLarge};
  margin-right: ${props => props.theme.spacingLarge};
  ${media.small`
    ${({ theme }) =>
      theme &&
      css`
        margin-left: ${theme.spacing};
        margin-right: ${theme.spacing};
      `}
  `};
`

const NavIcon = styled.button.attrs({ type: 'button' })`
  height: 62px;
  width: 62px;
  line-height: 0px;
  background: ${({ theme }) => theme.cardNavigationBackground};
  border-radius: 62px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border: none;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -62px;
  z-index: 10;
  color: ${props => props.theme.cardNavigationIcon};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      &:hover {
        cursor: normal;
      }
      svg {
        opacity: 0.36;
      }
    `}

  ${media.small`
    display: none;
  
    ${({ showOnMobile }) =>
      showOnMobile &&
      css`
        display: block;
        height: 48px;
        width: 48px;
        border-radius: 48px;
        margin-top: -48px;

        ${({ previous }) =>
          previous &&
          css`
            left: 8px;
          `};

        ${({ next }) =>
          next &&
          css`
            right: 8px;
          `};
      `};
  `};

  ${media.medium`
    display: none;

    ${({ showOnMobile }) =>
      showOnMobile &&
      css`
        display: block;
      `};
  `};

  ${({ previous }) =>
    previous &&
    css`
      left: -31px;
    `};

  ${({ next }) =>
    next &&
    css`
      right: -31px;
    `};

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
`

const SliderWrapper = styled.div`
  position: relative;
  padding: 0;
`
const SliderContent = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  transform: translate3d(0, 0, 0);
  will-change: scroll-position;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`

const SliderContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: no-wrap;
  ${({ theme }) =>
    theme &&
    css`
      padding-left: ${theme.spacingLarge};
      padding-right: ${theme.spacingLarge};
      padding-bottom: ${theme.spacing};
    `};

  ${media.small`
    padding-left: ${({ theme }) => theme.spacing};
    padding-right: ${({ theme }) => theme.spacing};
  `};
`

const CardListTitle = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      margin-left: ${theme.spacingLarge};
      margin-right: ${theme.spacingLarge};
    `};

  ${media.small`
    ${({ theme }) =>
      theme &&
      css`
        margin-left: ${theme.spacing};
        margin-right: ${theme.spacing};
      `};
  `};
`

const BackArrow = ({ onClick, disabled, showOnMobile }) => (
  <NavIcon
    previous
    onClick={onClick}
    disabled={disabled}
    aria-label="Scroll til venstre"
    showOnMobile={showOnMobile}
  >
    <ArrowBackIcon size={32} />
  </NavIcon>
)

BackArrow.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  showOnMobile: PropTypes.bool,
}

BackArrow.defaultProps = {
  onClick: () => { },
  disabled: false,
  showOnMobile: false,
}

const NextArrow = ({ onClick, disabled, showOnMobile }) => (
  <NavIcon
    disabled={disabled}
    onClick={onClick}
    next
    aria-label="Scroll til høyre"
    showOnMobile={showOnMobile}
  >
    <ArrowForwardIcon size={32} />
  </NavIcon>
)

NextArrow.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  showOnMobile: PropTypes.bool,
}

NextArrow.defaultProps = {
  onClick: () => { },
  disabled: false,
  showOnMobile: false,
}

const RatingsButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${({ theme }) =>
    theme &&
    `
    color: ${theme.linkColor};
    font-size: ${theme.fontSize};
  `};
`

class CardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nextActive: true,
      prevActive: false,
      nrOfRatings: null,
      trackedVisibility: false,
      trackedScrolling: false,
      trackedScrollEnd: false,
    }
    this.sliderContentRef = React.createRef()
    this.scrollInterval = null
    this.animating = false
  }

  componentDidMount() {
    smoothscroll.polyfill()
    this.handleNrOfRatings()
    this.handleScrollInit()
  }

  componentDidUpdate() {
    this.handleScrollInit()
  }

  trackVisibility = isVisible => {
    if (!isVisible) return
    const { trackedVisibility } = this.state
    if (!trackedVisibility) {
      this.setState(
        {
          trackedVisibility: true,
        },
        () => {
          const { title } = this.props
          trackEvent(`Showed cardlist ${title} in view`, 'cardlist', title)
        },
      )
    }
  }

  handleScrollInit = () => {
    let nextActive = true
    if (
      this.sliderContentRef.current &&
      this.sliderContentRef.current.scrollLeft ===
      this.sliderContentRef.current.scrollWidth - this.sliderContentRef.current.clientWidth
    ) {
      nextActive = false
    }
    this.setState(prevState => {
      if (prevState.nextActive !== nextActive) {
        return { nextActive }
      }
      return null
    })
  }

  handleNrOfRatings = () => {
    const { cards, type } = this.props
    let nrOfRatings = 0
    if (type === 'rating') {
      for (let i = 0; i < cards.length && !nrOfRatings; i += 1) {
        if (cards[i].data.completedratings) {
          nrOfRatings += cards[i].data.completedratings
        }
      }
    }
    this.setState({ nrOfRatings })
  }

  animateScrollTo = toX => {
    const { trackedScrolling } = this.state
    const { title } = this.props
    if (this.sliderContentRef.current) {
      this.sliderContentRef.current.scroll({
        left: toX,
        behavior: 'smooth',
      })
    }
    if (!trackedScrolling) trackEvent(`Scrolled ${title}`, 'cardlist', title)
    this.setState({ animating: false, trackedScrolling: true })
  }

  handleScroll = () => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }
    this.scrollTimeout = setTimeout(() => {
      const { trackedScrolling } = this.state
      const { title } = this.props
      if (!trackedScrolling) trackEvent(`Scrolled ${title}`, 'cardlist', title)
      const { trackedScrollEnd } = this.state
      let newTrackedScrollEnd = trackedScrollEnd
      let prevActive = true
      let nextActive = true
      if (this.sliderContentRef.current && this.sliderContentRef.current.scrollLeft === 0) {
        prevActive = false
      }
      if (
        this.sliderContentRef.current &&
        this.sliderContentRef.current.scrollLeft ===
        this.sliderContentRef.current.scrollWidth - this.sliderContentRef.current.clientWidth
      ) {
        nextActive = false
        newTrackedScrollEnd = true
        if (!trackedScrollEnd) trackEvent(`Scrolled to end ${title}`, 'cardlist', title)
      }
      this.setState({
        prevActive,
        nextActive,
        trackedScrolling: true,
        trackedScrollEnd: newTrackedScrollEnd,
      })
    }, 25)
  }

  scrollTo = direction => {
    const { animating } = this.state
    if (!animating) {
      this.setState({ animating: true })
      if (this.sliderContentRef.current && this.sliderContentRef.current.querySelector('div')) {
        const cardList = this.sliderContentRef.current.querySelector('div')
        if (cardList.childNodes[0]) {
          const cardWidth = 300 // cardList.childNodes[0].offsetWidth
          const directionValue = direction === 'next' ? 1 : -1
          const xTarget = Math.min(
            this.sliderContentRef.current.scrollLeft + cardWidth * directionValue * 2,
            this.sliderContentRef.current.scrollWidth - this.sliderContentRef.current.clientWidth,
          )
          this.animateScrollTo(xTarget, cardWidth * directionValue * 2, direction)
        }
      }
    }
  }

  handleKeyUp = evt => {
    switch (evt.keyCode) {
      case 37:
        evt.preventDefault()
        this.scrollTo('back')
        break
      case 39:
        evt.preventDefault()
        this.scrollTo('next')
        break
      default:
        return false
    }
    return false
  }

  render() {
    const {
      id,
      title,
      subtitle,
      subHeader,
      cards,
      nabolag,
      type,
      toggleInfoDialog,
      removeTopMargin,
      showNavigationArrowsOnMobile,
      t,
      theme,
    } = this.props
    const { nrOfRatings, prevActive, nextActive } = this.state;

    if (cards.length < 1) {
      return null
    }

    let hideBorder = this.props.hideBorder;
    if (!hideBorder && theme && theme.themeShadow && theme.themeShadow === 'none'){
      hideBorder = true;
    }

    // Show rating-info on card if card is not in rating-card-list
    const showRatingInfoOnCard = type !== 'rating'
    // Loop through cards data and render relevant card component
    const CardComponents = cards.map((card, index) => {
      const cardKey = `${type}card-${card.type}-${index.toString()}`
      if (card.data && card.data.pois) {
        return <Poi card={card} key={cardKey} />
      }
      const showRatingOnThisCard = showRatingInfoOnCard && card.type === cardTypes.RATING
      switch (card.type) {
        case cardTypes.RATING:
        case cardTypes.FAMILIES:
        case cardTypes.GREEN_TRANSPORT:
        case cardTypes.ACTIVITIES:
        case cardTypes.SPORTS_ACTIVITY:
        case cardTypes.SPORTS: {
          const cityName = nabolag.neighborhood ? nabolag.neighborhood.city : nabolag.municipality
          return (
            <Rating
              card={card}
              cityName={cityName}
              key={cardKey}
              showRatingInfo={showRatingOnThisCard}
            />
          )
        }
        case cardTypes.QUOTE:
          return <Quote card={card} key={cardKey} />
        case cardTypes.SUN_CURVE: {
          if (nabolag && nabolag.sunCurves) {
            return <SunCurve card={card} suncurves={nabolag.sunCurves} key={cardKey} />
          }
          return null
        }
        case cardTypes.RECOMMENDED_FOR:
          return <Recommended card={card} key={cardKey} />
        case cardTypes.AGE:
          return <Age card={card} nabolag={nabolag} key={cardKey} />
        case cardTypes.COMPARE:
          return <Compare card={card} orderLineId={nabolag.orderLineId} key={cardKey} />
        case cardTypes.TRAVEL_ROUTE:
          return <TravelRouteCardContainer nabolag={nabolag} key={cardKey} />
        case cardTypes.IMAGE:
          return <Image card={card} key={cardKey} />
        case cardTypes.SKI_TRACKS:
          return <SkiTracks card={card} key={cardKey} />
        case cardTypes.ALPINE:
          return <Alpine card={card} key={cardKey} />
        default:
          console.log(`Could not find a matching Card for card with type '${card.type}'`) // eslint-disable-line no-console
          return null
      }
    })
    return (
      <VisibilitySensor onChange={this.trackVisibility}>
        <CardWrapper removeTopMargin={removeTopMargin}>
          {!hideBorder ? <CardBorder /> : null}
          {id ? <Anchor id={id} /> : null}
          <CardListTitle>
            <H2 noResponsive>{title}</H2>
            <SubTitle>
              {subtitle}
              {subHeader.map(item => (
                <SubTitleItem key={item}>{item}</SubTitleItem>
              ))}
            </SubTitle>
            {type === 'rating' && nrOfRatings > 0 ? (
              <RatingsButton onClick={() => toggleInfoDialog()}>
                {t('common:ratedByNrOfUsers', { ratings: nrOfRatings })}
              </RatingsButton>
            ) : null}
          </CardListTitle>
          <SliderWrapper>
            <BackArrow
              onClick={() => this.scrollTo('back')}
              disabled={!prevActive}
              showOnMobile={showNavigationArrowsOnMobile}
            />
            <SliderContent
              ref={this.sliderContentRef}
              onScroll={this.handleScroll}
              onKeyDown={this.handleKeyUp}
            >
              <SliderContentWrapper>{CardComponents}</SliderContentWrapper>
            </SliderContent>
            <NextArrow
              onClick={() => this.scrollTo('next')}
              disabled={!nextActive}
              showOnMobile={showNavigationArrowsOnMobile}
            />
          </SliderWrapper>
        </CardWrapper>
      </VisibilitySensor>
    )
  }
}

CardList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subHeader: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  nabolag: PropTypes.shape().isRequired,
  toggleInfoDialog: PropTypes.func.isRequired,
  showNavigationArrowsOnMobile: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  hideBorder: PropTypes.bool,
  removeTopMargin: PropTypes.bool,
}

CardList.defaultProps = {
  id: '',
  title: '',
  subtitle: '',
  subHeader: [],
  type: 'default',
  hideBorder: false,
  removeTopMargin: false,
}

const mapDispatchToProps = dispatch => ({ toggleInfoDialog: () => dispatch(toggleDialog()) })

const mapStateToProps = (state) => ({
  showNavigationArrowsOnMobile: getShowCardListArrowsOnMobile(state),
})


export default compose(
  withTranslation(['common']),
  withTheme,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CardList)
