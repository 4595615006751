const cardTypes = {
  PUBLIC_TRANSPORT: 'PUBLIC_TRANSPORT',
  FAMILIES: 'FAMILIES',
  SCHOOLS: 'SCHOOLS',
  HOUSING_STOCK: 'HOUSING_STOCK',
  SUN_CURVE: 'SUN_CURVE',
  RECOMMENDED_FOR: 'RECOMMENDED_FOR',
  RATING: 'RATING',
  QUOTE: 'QUOTE',
  SAFETY: 'SAFETY',
  KINDERGARDENS: 'KINDERGARDENS',
  SEMIDETACHED: 'SEMI-DETACHED',
  AGE: 'AGE',
  HIKING: 'HIKING',
  NEIGHBORLINESS: 'NEIGHBORLINESS',
  GREEN_TRANSPORT: 'GREEN_TRANSPORT',
  SPORTS: 'SPORTS',
  SHOPPING: 'SHOPPING',
  UNIVERSITY: 'UNIVERSITY',
  COMPARE: 'COMPARE',
  TRAVEL_ROUTE: 'TRAVEL_ROUTE',
  IMAGE: 'IMAGE',
  SKI_TRACKS: 'SKI_TRACKS',
  ACTIVITIES: 'ACTIVITIES',
  ALPINE: 'ALPINE',
  SPORTS_ACTIVITY: 'SPORTS_ACTIVITY',
  STREET_PARKING: 'STREET_PARKING',
  TRAFFIC: 'TRAFFIC',
  REGIONAL_TRANSPORT: 'REGIONAL_TRANSPORT',
  LOCAL_TRANSPORT: 'LOCAL_TRANSPORT',
  TAXI_AND_PARKING: 'TAXI_AND_PARKING',
  LARGE_BUSINESSES: 'LARGE_BUSINESSES',
  RATING_PUBLIC_TRANSPORT: 'RATING_PUBLIC_TRANSPORT',
  HARBOUR: 'HARBOUR',
}

export const cartTypeCategory = {
  COMPARE: 'ENVIRONMENT',
  HOUSING_STOCK: 'ENVIRONMENT',
  SUN_CURVE: 'ENVIRONMENT',
  SAFETY: 'ENVIRONMENT',
  FAMILIES: 'FAMILY',
  KINDERGARDENS: 'FAMILY',
  SCHOOLS: 'FAMILY',
  SPORTS: 'LEISURE',
  HIKING: 'LEISURE',
  RECOMMENDED_FOR: 'PEOPLE',
  AGE: 'PEOPLE',
  QUOTE: 'PEOPLE',
  PUBLIC_TRANSPORT: 'TRANSPORT',
  SHOPPING: 'SHOPPING',
  UNIVERSITY: 'PEOPLE',
  NEIGHBORLINESS: 'ENVIRONMENT',
  GREEN_TRANSPORT: 'TRANSPORT',
  TRAVEL_ROUTE: 'TRAVEL-TIME',
  SKI_TRACKS: 'ACTIVITIES',
  HARBOUR: 'ACTIVITIES',
  ACTIVITIES: 'ACTIVITIES',
  ALPINE: 'ACTIVITIES',
  SPORTS_ACTIVITY: 'ACTIVITIES',
  STREET_PARKING: 'TRANSPORT',
  TRAFFIC: 'TRANSPORT',
  REGIONAL_TRANSPORT: 'TRANSPORT',
  LOCAL_TRANSPORT: 'TRANSPORT',
  TAXI_AND_PARKING: 'TRANSPORT',
  LARGE_BUSINESSES: 'BUSINESSES',
  RATING_PUBLIC_TRANSPORT: 'TRANSPORT',
}

export const cardTypeLinkText = {
  COMPARE: 'ENVIRONMENT',
  HOUSING_STOCK: 'ENVIRONMENT',
  SUN_CURVE: 'ENVIRONMENT',
  SAFETY: 'ENVIRONMENT',
  FAMILIES: 'FAMILY',
  KINDERGARDENS: 'FAMILY',
  SCHOOLS: 'FAMILY',
  SPORTS: 'LEISURE',
  HIKING: 'LEISURE',
  RECOMMENDED_FOR: 'PEOPLE',
  AGE: 'PEOPLE',
  QUOTE: 'PEOPLE',
  PUBLIC_TRANSPORT: 'TRANSPORT',
  SHOPPING: 'SHOPPING',
  UNIVERSITY: 'PEOPLE',
  NEIGHBORLINESS: 'ENVIRONMENT',
  GREEN_TRANSPORT: 'TRANSPORT',
  TRAVEL_ROUTE: 'TRAVEL-TIME',
  SKI_TRACKS: 'SKITRACKS',
  HARBOUR: 'ACTIVITIES',
  ACTIVITIES: 'ACTIVITIES',
  ALPINE: 'ALPINE',
  SPORTS_ACTIVITY: 'ACTIVITIES',
  STREET_PARKING: 'TRANSPORT',
  TRAFFIC: 'TRANSPORT',
  REGIONAL_TRANSPORT: 'TRANSPORT',
  LOCAL_TRANSPORT: 'TRANSPORT',
  TAXI_AND_PARKING: 'TRANSPORT',
  LARGE_BUSINESSES: 'BUSINESSES',
  RATING_PUBLIC_TRANSPORT: 'TRANSPORT',
}

export default cardTypes
