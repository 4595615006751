import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'

import Card from './components/Card'
import { withTranslation } from '../../../i18n'

const SkiTracksList = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing};
  padding-right: ${({ theme }) => theme.spacing};
`
const SkiTracksItem = styled.li`
  line-height: 28px;
  margin-bottom: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColorLight};
`

const SkiTracks = props => {
  const { card, t } = props
  const { title, closestTrack, totalLength } = card.data
  return (
    <Card type={card.type} title={title}>
      <SkiTracksList>
        {closestTrack ? (
          <SkiTracksItem>
            {t('common:cards.SKI_TRACKS_CLOSEST', { distance: closestTrack })}
          </SkiTracksItem>
        ) : null}
        {totalLength ? (
          <SkiTracksItem>
            {t('common:cards.SKI_TRACKS_TOTAL_DISTANCE', { distance: totalLength })}
          </SkiTracksItem>
        ) : null}
      </SkiTracksList>
    </Card>
  )
}

SkiTracks.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(withTranslation(['common', 'map']))(SkiTracks)
