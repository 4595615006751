import React from 'react'
import PropTypes from 'prop-types'

import InfoText from '../info-text'
import CardList from '../CardList'

const Info = ({ infoTexts, mediaCards, nabolag }) => {
  const text1 = infoTexts.length ? infoTexts[0] : null
  const text2 = infoTexts.length > 1 ? infoTexts[1] : null
  const hasMedia = mediaCards.title && mediaCards.cards && mediaCards.cards.length
  return (
    <>
      {text1 ? <InfoText content={text1} /> : null}
      {hasMedia ? (
        <CardList
          title={mediaCards.title}
          cards={mediaCards.cards}
          nabolag={nabolag}
          hideBorder
          removeTopMargin
          noResponsive={false}
        />
      ) : null}
      {text2 ? <InfoText content={text2} /> : null}
    </>
  )
}

Info.propTypes = {
  infoTexts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mediaCards: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  nabolag: PropTypes.shape().isRequired,
}

export default Info
