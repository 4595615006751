import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { Wrapper, LinkContent, Img } from './style'

import { withTranslation } from '../../../i18n'
import InternalLink from '../../utils/internal-link'

const OverlayWrapper = styled.div`
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MapConvertion = React.memo(({ lat, long, t, onClick, overlay }) => {
  const [visible, setVisibility] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisibility(true)
  }

  const defaultSource = `https://maptiles.finncdn.no/staticmap?lat=${lat}&lng=${long}&zoom=14&size=920x250&maptype=normap&showPin=false`
  const desktopSourceHD = `https://maptiles.finncdn.no/staticmap?lat=${lat}&lng=${long}&zoom=14&size=920x250&maptype=normaphd&showPin=false`
  const desktopSourceXHD = `https://maptiles.finncdn.no/staticmap?lat=${lat}&lng=${long}&zoom=14&size=920x250&maptype=normapxhd&showPin=false`
  const mobileSource = `https://maptiles.finncdn.no/staticmap?lat=${lat}&lng=${long}&zoom=14&size=400x400&maptype=normap&showPin=false`
  const mobileSourceHD = `https://maptiles.finncdn.no/staticmap?lat=${lat}&lng=${long}&zoom=14&size=400x400&maptype=normaphd&showPin=false`
  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      offset={{ bottom: -250 }}
      active={!visible}
    >
      <Wrapper>
        <InternalLink route="map" passHref>
          <LinkContent
            role="button"
            aria-label={t('common.mapButton.altText')}
            title={t('common.mapButton.altText')}
            onClick={onClick}
          >
            {visible ? (
              <picture>
                <source
                  media="(max-width: 600px)"
                  srcSet={`${mobileSource}, ${mobileSourceHD} 2x`}
                />
                <source
                  media="(min-width: 601px)"
                  srcSet={`${defaultSource}, ${desktopSourceHD} 2x, ${desktopSourceXHD} 3x`}
                />
                <Img src={defaultSource} alt={t('common:mapButton.altText')} />
              </picture>
            ) : null}
            {overlay && <OverlayWrapper>{overlay()}</OverlayWrapper>}
          </LinkContent>
        </InternalLink>
      </Wrapper>
    </VisibilitySensor>
  )
})

MapConvertion.propTypes = {
  lat: PropTypes.number.isRequired,
  long: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  overlay: PropTypes.elementType,
}

MapConvertion.defaultProps = {
  onClick: null,
}

export default compose(withTranslation(['common']))(MapConvertion)
