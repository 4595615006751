import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import storage from 'local-storage-fallback'

import { LinkButton } from '../../ui'
import AppContext from '../../hocs/AppContext'
import Card from './components/Card'
import { withTranslation } from '../../../i18n'

const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    `
    padding: ${theme.spacing};
    margin-top: ${theme.spacing};
  `}
  a {
    font-size: 16px;
    display: block;
    position: relative;
    margin: 0 auto 0 auto;
    text-align: center;
  }
`

const CompareCard = props => {
  const { card, orderLineId, t } = props
  const { type } = card
  const { title } = card.data
  const isClient = typeof window !== 'undefined'
  const selectedAddressId = storage.getItem('selectedAddressId')
  const selectedAddressLabel = storage.getItem('selectedAddressLabel')
  const categoryStr = t('categories:family').toLowerCase()
  return (
    <Card type={type} title={title}>
      <AppContext.Consumer>
        {context => (
          <React.Fragment>
            {selectedAddressId && isClient ? (
              <Wrapper>
                <LinkButton
                  route="comparison"
                  params={{
                    id: context.query.id,
                    finnkode: orderLineId,
                    comparisonId: selectedAddressId,
                    category: categoryStr,
                  }}
                  filledBg
                >
                  {selectedAddressLabel}
                </LinkButton>
              </Wrapper>
            ) : (
              <Wrapper>
                <LinkButton route="address" filledBg>
                  {t('common:addressSearchPlaceholder')}
                </LinkButton>
              </Wrapper>
            )}
          </React.Fragment>
        )}
      </AppContext.Consumer>
    </Card>
  )
}

CompareCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({ title: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  orderLineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(withTranslation(['common', 'categories']))(CompareCard)
