import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import storage from 'local-storage-fallback'

import { withNabolag } from '../../hocs/withNabolag'
import * as api from '../../store/api'
import {
  getStoredAddressesAction,
  initPublicRouteFetchAction,
  publicRouteFetchFailedAction,
  completePublicRouteFetchAction,
  initRouteInfoFetchAction,
  completeRouteInfoFetchAction,
  routeInfoFetchFailedAction,
} from '../../store/travel-time/actions'
import {
  makeGetRouteInfo,
  makeGetPublicRouteInfoSelector,
  makeGetNonPublicRouteInfoSelector,
  getAddressesSelector,
  hasCheckedStorageSelector,
} from '../../store/travel-time/selectors'
import { getRouteCalculation } from '../../store/nabolag/selectors'
import isServer from '../../utils/is-server'
import { trackEvent } from '../../utils/analytics'
import getTravelTimeStorageKey from '../../utils/getTravelTimeStorageKey'

import { TravelCard } from '../card'

const getAllRoutes = makeGetRouteInfo()
const getPublicRoutes = makeGetPublicRouteInfoSelector()
const getNonPublicRoutes = makeGetNonPublicRouteInfoSelector()

const TravelRouteCardContainer = ({ nabolag }) => {
  const [fastestPublicRoute, setFastestPublicRoute] = useState(null)
  const [primaryRoute, setPrimaryRoute] = useState(null)
  const [loading, setLoading] = useState(true)
  const [storedAddresses, setStoredAddresses] = useState(null)

  const dispatch = useDispatch()

  const addresses = useSelector(getAddressesSelector)
  const publicRoutes = useSelector((state) =>
    getPublicRoutes(state, addresses[0] && addresses[0].id),
  )
  const nonPublicRoutes = useSelector((state) =>
    getNonPublicRoutes(state, addresses[0] && addresses[0].id),
  )
  const allRoutes = useSelector((state) => getAllRoutes(state, addresses[0] && addresses[0].id))
  const routeCalculation = useSelector(getRouteCalculation) || {}
  const hasCheckedStorage = useSelector(hasCheckedStorageSelector)

  useEffect(() => {
    if (!isServer()) {
      trackEvent('Loaded travelcard on frontpage', 'travel-time')
    }
  }, [])

  useEffect(() => {
    const localAddresses =
      JSON.parse(storage.getItem(getTravelTimeStorageKey(routeCalculation.prefix))) || []
    if (localAddresses.length > 0) {
      setStoredAddresses({
        ...storedAddresses,
        local: localAddresses,
      })
    }
  }, [])

  useEffect(() => {
    if ((!Array.isArray(addresses) || addresses.length < 1) && !hasCheckedStorage) {
      dispatch(getStoredAddressesAction(getTravelTimeStorageKey(routeCalculation.prefix)))
    }
  }, [addresses])

  useEffect(() => {
    if (Array.isArray(addresses) && addresses.length > 0) {
      const address = addresses[0]

      const addrQuery = {
        id: nabolag.finnkode || nabolag.orderLineId,
        lat: address.location.lat,
        lng: address.location.lng,
      }

      ;(async () => {
        try {
          if (
            (Array.isArray(publicRoutes) && publicRoutes.length > 0) ||
            address.isFetchingPublicRoute ||
            address.hasFetchedPublicRoute
          ) {
            return
          }

          dispatch(initPublicRouteFetchAction(address.id))

          const res = await api.findPublicTransportRoutes(addrQuery)
          const data = await res.json()

          dispatch(completePublicRouteFetchAction(address.id, data))
        } catch (err) {
          dispatch(publicRouteFetchFailedAction(address.id, err))
          console.error('publicRouteFetchFailedAction', err)
        }
      })()
      ;(async () => {
        try {
          if (
            (Array.isArray(nonPublicRoutes) && nonPublicRoutes.length > 0) ||
            address.isFetchingRoute ||
            address.hasFetchedRoute
          ) {
            return
          }

          dispatch(initRouteInfoFetchAction(address.id))

          const res = await api.findTravelRoutes(addrQuery)
          const data = await res.json()

          dispatch(completeRouteInfoFetchAction(address.id, data))
        } catch (err) {
          dispatch(routeInfoFetchFailedAction(address.id, err))
          console.error('routeInfoFetchFailedAction', err)
        }
      })()
    }
  }, [addresses])

  useEffect(() => {
    if (Array.isArray(publicRoutes) && publicRoutes.length > 0) {
      setFastestPublicRoute(publicRoutes.find((route) => route.type === 'fastest'))
    }
  }, [publicRoutes])

  useEffect(() => {
    if (Array.isArray(allRoutes) && allRoutes.length > 0) {
      setPrimaryRoute(allRoutes.find((route) => route.primary))
    }
  }, [allRoutes])

  useEffect(() => {
    if (Array.isArray(addresses) && addresses.length > 0) {
      const address = addresses[0]
      setLoading(address.isFetchingPublicRoute || address.isFetchingRoute)
    } else {
      setLoading(false)
    }
  }, [fastestPublicRoute, primaryRoute])

  // We need to ensure no return if no address
  if (!Array.isArray(addresses) || addresses.length === 0) {
    return null
  }

  return (
    <TravelCard
      publicTravelTime={fastestPublicRoute}
      primaryTravelTime={primaryRoute}
      address={addresses[0]}
      loading={loading}
      i18n={routeCalculation.i18n}
    />
  )
}

TravelRouteCardContainer.propTypes = {
  nabolag: PropTypes.shape({
    finnkode: PropTypes.string.isRequired,
    orderLineId: PropTypes.number,
  }),
}

export default withNabolag(TravelRouteCardContainer)
