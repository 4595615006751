import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { compose } from 'recompose'

import PoiIcon from '../../ui/PoiIcon'
import TravelIcon from '../../ui/TravelIcon'
import Card from './components/Card'

const PoiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 10px;
  height: auto;
  min-height: 75%;
  font-size: 16px;
`
const PoiList = styled.ul`
  padding: 0;
  flex: 0 1 auto;
  margin: 0;
`
const PoiItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  ${({ theme }) =>
    theme &&
    `
    padding: ${theme.spacingSmall} ${theme.spacing} ${theme.spacingSmall} ${theme.spacing};
  `};
`
const PoiItemName = styled.div`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
`
const PoiTravelIcon = styled.div`
  flex: 0 1 24px;
  text-align: right;
  position: relative;
  top: -0px;
`
const PoiItemTravel = styled.div`
  flex: 0 0 50px;
  text-align: right;
  white-space: nowrap;
`
const PoiIconWrapper = styled.div`
  padding-right: 5px;
`
const PoiCard = props => {
  const { card, theme } = props
  const type = card.data.type || card.type
  const { title } = card.data
  const fullHeight = card.data.pois ? card.data.pois.length > 4 : false
  const poiTypeIds = card.data.pois.map(poi => poi.poiTypeId)
  const uniquePoiTypeIds = Array.from(new Set(poiTypeIds))
  const showIcon = uniquePoiTypeIds.length > 1 && type !== 'SCHOOLS'
  return (
    <Card type={type} title={title} fullHeight={fullHeight}>
      <PoiWrapper>
        <PoiList>
          {card.data.pois.map(poi => (
            <PoiItem
              key={`card-poi-${poi.name}-${poi.poiTypeId}-${poi.poiTypeName}-${poi.distance}`}
            >
              {showIcon ? (
                <PoiIconWrapper>
                  <PoiIcon typeId={poi.poiTypeId} color={theme.fontColorLight} />
                </PoiIconWrapper>
              ) : null}
              <PoiItemName>{type === 'PUBLIC_TRANSPORT' ? poi.poiTypeName : poi.name}</PoiItemName>
              {poi.distanceType && poi.distanceType !== 'air' ? (
                <PoiTravelIcon>
                  <TravelIcon type={poi.distanceType} />
                </PoiTravelIcon>
              ) : null}
              {poi.distance ? <PoiItemTravel>{poi.distance}</PoiItemTravel> : null}
            </PoiItem>
          ))}
        </PoiList>
      </PoiWrapper>
    </Card>
  )
}

PoiCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      pois: PropTypes.arrayOf(PropTypes.shape()),
      title: PropTypes.string,
    }).isRequired,
  }),
  theme: PropTypes.shape().isRequired,
}

export default compose(withTheme)(PoiCard)
