import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CardTypes from '../../../models/CardTypes'
import {
  Bicycle,
  Blackboard,
  Businesses,
  Chat,
  Family,
  GreenTransport,
  Groceries,
  Hiking,
  Home,
  Like,
  JusticeScale,
  Neighborhood,
  Padlock,
  SchoolBus,
  Sports,
  Stopwatch,
  CrossCountrySkiing,
  Bowling,
  DownhillSkiing,
  ParkingStreet,
  TrafficLight,
  TransportLong,
  TransportRating,
  ParkingMeter,
  Harbour,
} from '../../icons'

const CardIconWrapper = styled.div`
  text-align: right;
  position: absolute;
  bottom: ${({ theme }) => theme.spacing};
  right: 19px;
  z-index: 2;
  ${({ fade }) =>
    fade &&
    `
    opacity: 0.24;
  `};
`

function getCardIcon(type) {
  switch (type) {
    case CardTypes.PUBLIC_TRANSPORT:
      return SchoolBus
    case CardTypes.FAMILIES:
      return Family
    case CardTypes.SCHOOLS:
      return Blackboard
    case CardTypes.HOUSING_STOCK:
      return Home
    case CardTypes.RECOMMENDED_FOR:
      return Like
    case CardTypes.SAFETY:
      return Padlock
    case CardTypes.QUOTE:
      return Chat
    case CardTypes.KINDERGARDENS:
      return Bicycle
    case CardTypes.SEMIDETACHED:
      return Home
    case CardTypes.HIKING:
      return Hiking
    case CardTypes.SPORTS:
    case CardTypes.SPORTS_ACTIVITY:
      return Sports
    case CardTypes.SHOPPING:
      return Groceries
    case CardTypes.GREEN_TRANSPORT:
      return GreenTransport
    case CardTypes.AGE:
      return null
    case CardTypes.NEIGHBORLINESS:
      return Neighborhood
    case CardTypes.COMPARE:
      return JusticeScale
    case CardTypes.TRAVEL_ROUTE:
      return Stopwatch
    case CardTypes.SKI_TRACKS:
      return CrossCountrySkiing
    case CardTypes.ALPINE:
      return DownhillSkiing
    case CardTypes.ACTIVITIES:
      return Bowling
    case CardTypes.STREET_PARKING:
      return ParkingStreet
    case CardTypes.LOCAL_TRANSPORT:
      return SchoolBus
    case CardTypes.TRAFFIC:
      return TrafficLight
    case CardTypes.REGIONAL_TRANSPORT:
      return TransportLong
    case CardTypes.TAXI_AND_PARKING:
      return ParkingMeter
    case CardTypes.LARGE_BUSINESSES:
      return Businesses
    case CardTypes.RATING_PUBLIC_TRANSPORT:
      return TransportRating
    case CardTypes.HARBOUR:
      return Harbour
    default:
      return null
  }
}

const CardIcon = ({ type, fade, ...props }) => {
  const Icon = getCardIcon(type)
  return (
    <CardIconWrapper {...props} fade={fade}>
      {Icon ? <Icon width={89} height={89} /> : ''}
    </CardIconWrapper>
  )
}

CardIcon.propTypes = {
  type: PropTypes.string.isRequired,
  fade: PropTypes.bool.isRequired,
}

export default CardIcon
