import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { toggleDialog } from '../../store/ui/actions'
import Card from './components/Card'
import { withTranslation } from '../../../i18n'

const RatingValues = styled.div`
  padding: 0 ${({ theme }) => theme.spacing};
  color: ${({ theme }) => theme.fontColorLight};
  margin-top: 23px;
`
const RatingValue = styled.div``

const RatingLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-top: ${({ theme }) => theme.spacing};
  line-height: 24px;
`

const RatingsButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;

  &:hover {
    text-decoration: underline;
  }
  font-size: 12px;
  ${({ theme }) =>
    theme &&
    `
    color: ${theme.brandColor};
  `};
`

const RatingCard = props => {
  const { card, cityName, t, toggleInfoDialog, showRatingInfo } = props
  const { title, completedratings } = card.data
  const type = card.data.type || card.type
  return (
    <Card type={type} title={title}>
      <RatingValues>
        {card.data.score ? (
          <React.Fragment>
            <RatingValue>
              {card.data.score.neighborhood || card.data.score.census}/100 {card.data.score.text}
            </RatingValue>
            <RatingLabel>{cityName}</RatingLabel>
            <RatingValue>
              {card.data.score.city || card.data.score.municipality}/100 {card.data.score.text}
            </RatingValue>
          </React.Fragment>
        ) : null}
        {card.data.withChildrenShare ? (
          <React.Fragment>
            <RatingValue>
              {t(`common:cards.${type}`, {
                value: Math.round(
                  card.data.withChildrenShare.neighborhood || card.data.withChildrenShare.census,
                ),
              })}
            </RatingValue>
            <RatingLabel>{cityName}</RatingLabel>
            <RatingValue>
              {t(`common:cards.${type}`, {
                value: Math.round(
                  card.data.withChildrenShare.city || card.data.withChildrenShare.municipality,
                ),
              })}
            </RatingValue>
          </React.Fragment>
        ) : null}
      </RatingValues>
      {showRatingInfo ? (
        <RatingsButton onClick={() => toggleInfoDialog()}>
          {t('common:ratedByNrOfUsers', { ratings: completedratings })}
        </RatingsButton>
      ) : null}
    </Card>
  )
}

RatingCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  cityName: PropTypes.string.isRequired,
  toggleInfoDialog: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  showRatingInfo: PropTypes.bool,
}

RatingCard.defaultProps = {
  showRatingInfo: false,
}

const mapDispatchToProps = dispatch => ({ toggleInfoDialog: () => dispatch(toggleDialog()) })

export default compose(
  withTranslation(['common']),
  connect(
    null,
    mapDispatchToProps,
  ),
)(RatingCard)
