import React from 'react'
import PropTypes from 'prop-types'

import Suncurves from '../chart/components/Suncurves'
import Card from './components/Card'

const SunCurveCard = props => {
  const { card, suncurves } = props
  const { title } = card.data
  const type = card.data.type || card.type
  return (
    <Card type={type} title={title}>
      <Suncurves data={suncurves} inCard />
    </Card>
  )
}

SunCurveCard.propTypes = {
  card: PropTypes.shape({
    data: PropTypes.shape({ title: PropTypes.string.isRequired }).isRequired,
    type: PropTypes.string.isRequired,
  }),
  suncurves: PropTypes.shape({
    elevation: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    stats: PropTypes.shape({
      spring: PropTypes.shape(),
      summer: PropTypes.shape(),
      winter: PropTypes.shape(),
    }).isRequired,
  }),
}

export default SunCurveCard
