import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from './components/Card'

const ContentList = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing};
  padding-right: ${({ theme }) => theme.spacing};
`
const Item = styled.li`
  line-height: 28px;
  margin-bottom: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColorLight};
`

const Alpine = props => {
  const { card } = props
  const { title, distanceText, distance, skiLiftsText } = card.data
  return (
    <Card type={card.type} title={title}>
      <ContentList>
        {distanceText ? (
          <Item>
            {distanceText} {distance}
          </Item>
        ) : null}
        {skiLiftsText ? <Item>{skiLiftsText}</Item> : null}
      </ContentList>
    </Card>
  )
}

Alpine.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Alpine
