import styled from 'styled-components'

import Button from '../../ui/Button'
import media from '../layout/media'

export const Wrapper = styled.section`
  min-height: 120px;

  ${media.small`
    min-height: 280px;
  `};
`

export const LinkContent = styled.a`
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
`
export const Img = styled.img`
  border-radius: 4px;
  max-width: 100%;
  box-shadow: ${({ theme }) =>
    theme.themeShadow ||
    '0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 8px rgba(0, 0, 0, 0.12);'};
`

export const MapButton = styled(Button)`
  color: ${({ theme }) => theme.mapConversionColor};
  background: ${({ theme }) => theme.mapConversionBackground};
`
