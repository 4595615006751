import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import CardAreaChart from '../chart/components/AreaChart'
import Card from './components/Card'
import { withTranslation } from '../../../i18n'

const AgeCard = props => {
  const { card, nabolag, t } = props
  const { title } = card.data
  const type = card.data.type || card.type
  const nabolagStr = nabolag.neighborhood
    ? nabolag.neighborhood.name
    : t('common:censusLabel', { value: nabolag.census })
  const cityStr = nabolag.neighborhood
    ? nabolag.neighborhood.city
    : t('common:municipalityLabel', { value: nabolag.municipality })
  return (
    <Card type={type} title={title}>
      <CardAreaChart
        data={{ values: card.data.stats }}
        type={card.type}
        nabolag={nabolagStr}
        city={cityStr}
      />
    </Card>
  )
}

AgeCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
    stats: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  nabolag: PropTypes.shape({
    neighborhood: PropTypes.shape({
      name: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
    }),
    census: PropTypes.string,
    municipality: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(withTranslation(['common']))(AgeCard)
