import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import {
  MarkerFill,
  Drive,
  Family,
  People,
  ShoppingMall,
  Lifestyle,
  LivingEnvironment,
  Football,
  Businesses,
} from '../poi-icons'
import { Exchange } from '../icons'

import { withCategories } from '../../hocs/withCategories'
import { withTranslation } from '../../../i18n'
import { H2 } from '../../ui/Headings'
import media from './media'
import { getShowRouteCalculation, getShowCompareBox } from '../../store/nabolag/selectors'

import { trackEvent } from '../../utils/analytics'
import InternalLink from '../../utils/internal-link'

const BottomNavWrapper = styled.div`
  background-color: ${({ theme }) => theme.contentBGColor};

  padding: 0
    ${({ theme, parent }) =>
    `${parent === 'travelTime' ? theme.spacing : theme.spacingLarge} ${theme.spacingLarge}`};

  ${media.small`
    padding: 0 ${({ theme }) => `${theme.spacing} ${theme.spacing}`};
  `};
`
const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  line-height: 34px;
  display: block;
  padding: 0;
`
const MenuItem = styled.li`
  list-style: none;
  font-size: 18px;
  line-height: 45px;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`
const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  &:hover {
    i {
      color: ${({ theme }) => theme.brandColor};
    }
    svg {
      fill: ${({ theme }) => theme.bottomNavIconHoverColor};
    }
  }
`
const LinkText = styled.span`
  flex: 1 1 87.5%;
  border-bottom: 1px solid ${({ theme }) => theme.bottomNavBorderColor};
  vertical-align: middle;
`
const LinkIcon = styled.i`
  flex: 0 1 34px;
  color: ${({ theme }) => theme.bottomNavIconColor};
  height: 45px;
  width: 28px;
  vertical-align: middle;
  svg {
    vertical-align: middle;
    position: relative;
    top: -3px;
  }
  ${({ topoffset }) =>
    topoffset &&
    `
    svg {
      top: -5px;
    }
  `};
`

const Spacer = styled.div`
  height: 20px;
  width: 100%;
`

const getIcon = (type) => {
  switch (type) {
    case 'family':
      return <Family size="small" />
    case 'transport':
      return <Drive size="small" />
    case 'people':
      return <People size="small" />
    case 'shopping':
      return <ShoppingMall size="small" />
    case 'leisure':
    case 'activities':
      return <Football size="small" />
    case 'environment':
      return <LivingEnvironment size="small" />
    case 'businesses':
      return <Businesses size="small" />
    default:
      return null
  }
}

const BottomNav = ({ categories, t, source, parent }) => {
  const showTravelTime = useSelector(getShowRouteCalculation)
  const showCompareBox = useSelector(getShowCompareBox)
  return (
    <BottomNavWrapper parent={parent}>
      <H2 noResponsive>{t('common:exploreCategories')}</H2>
      <MenuList role="menubar" ariaLabel="Category navigation">
        {categories && categories.length > 0 && categories.map((item) => (
          <MenuItem role="presentation" key={item.id}>
            <InternalLink route={item.id} passHref>
              <LinkWrapper
                role="menuitem"
                onClick={() => {
                  trackEvent(`Clicked nav item from ${source}`, 'Bottom nav', item.name)
                }}
              >
                <LinkIcon>{getIcon(item.id)}</LinkIcon>
                <LinkText>{item.name}</LinkText>
              </LinkWrapper>
            </InternalLink>
          </MenuItem>
        ))}
      </MenuList>
      <Spacer />
      <H2 noResponsive>{t('common:bottomMoreActions')}</H2>
      <MenuList role="menubar" ariaLabel="Other services">
        {showTravelTime ? (
          <MenuItem role="presentation">
            <InternalLink route="travel-time-redirect" passHref>
              <LinkWrapper
                role="menuitem"
                onClick={() => {
                  trackEvent(
                    `Clicked nav item from ${source}`,
                    'Bottom nav',
                    t('common:menu.travel-time'),
                  )
                }}
              >
                <LinkIcon>
                  <Lifestyle size="small" />
                </LinkIcon>
                <LinkText>{t('common:menu.travel-time')}</LinkText>
              </LinkWrapper>
            </InternalLink>
          </MenuItem>
        ) : null}
        {showCompareBox ? (
          <MenuItem role="presentation">
            <InternalLink route="comparison-redirect" passHref>
              <LinkWrapper
                role="menuitem"
                onClick={() => {
                  trackEvent(
                    `Clicked nav item from ${source}`,
                    'Bottom nav',
                    t('common:menu.comparison'),
                  )
                }}
              >
                <LinkIcon>
                  <Exchange size="small" />
                </LinkIcon>
                <LinkText>{t('common:menu.comparison')}</LinkText>
              </LinkWrapper>
            </InternalLink>
          </MenuItem>
        ) : null}
        <MenuItem role="presentation">
          <InternalLink route="map" passHref>
            <LinkWrapper
              role="menuitem"
              onClick={() => {
                trackEvent(`Clicked nav item from ${source}`, 'Bottom nav', t('common:menu.map'))
              }}
            >
              <LinkIcon>
                <MarkerFill size="small" />
              </LinkIcon>
              <LinkText>{t('common:menu.map')}</LinkText>
            </LinkWrapper>
          </InternalLink>
        </MenuItem>
      </MenuList>
    </BottomNavWrapper>
  )
}

BottomNav.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  source: PropTypes.string,
}

BottomNav.defaultProps = {
  source: '',
}

export default compose(
  React.memo,
  withCategories,
  withTranslation(['common']),
)(BottomNav)
