import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { trackProductCode } from '../../utils/analytics'

import { withNabolag } from '../../hocs/withNabolag'
import AppContext from '../../hocs/AppContext'
import {
  getFactCards,
  getRatingCards,
  getRouteCalculation,
  getInfoText,
  getMediaCards,
  getProductCode,
} from '../../store/nabolag/selectors'
import Page from '../../components/layout/Page'
import Overview from '../../components/Overview'
import MetaTags from '../../components/MetaTags'

import isServer from '../../utils/is-server'
import { withTheme } from '../../hocs/withTheme'

const mapStateToProps = (state) => ({
  factCards: getFactCards(state),
  ratingCards: getRatingCards(state),
  routeCalculation: getRouteCalculation(state),
  infoText: getInfoText(state),
  mediaCards: getMediaCards(state),
  productCode: getProductCode(state),
})

class OverviewPage extends React.PureComponent {
  static async getInitialProps() {
    return {
      namespacesRequired: [
        'activities',
        'common',
        'categories',
        'error',
        'map',
        'businesses',
        'charts',
        'family',
        'transport',
        'people',
        'environment',
        'shopping',
        'leisure',
        'travel-time',
        'schibsted',
      ],
    }
  }

  constructor(props) {
    super(props)

    if (!isServer()) {
      const { productCode } = props
      trackProductCode(productCode)
    }
  }

  render() {
    const {
      nabolag,
      factCards,
      ratingCards,
      routeCalculation,
      infoText,
      mediaCards,
      query,
      path,
      usingCustomTheme,
    } = this.props
    const providerData = {
      query,
      path,
    }

    const noPageMenu = !!nabolag.error
    return (
      <Page
        noMenu={noPageMenu}
        error={nabolag.error}
        finnkode={query.id}
        displayBackToFinnLink={!usingCustomTheme}
      >
        <AppContext.Provider value={providerData}>
          {nabolag.orderLineId ? (
            <>
              <MetaTags title={nabolag.header} />
              <Overview
                nabolag={nabolag}
                location={nabolag.location || null}
                ratingCards={ratingCards}
                factCards={factCards}
                infoText={infoText}
                mediaCards={mediaCards}
                routeCalculation={routeCalculation}
              />
            </>
          ) : null}
        </AppContext.Provider>
      </Page>
    )
  }
}

OverviewPage.propTypes = {
  // hoc props
  nabolag: PropTypes.shape({}).isRequired,
  // state props
  factCards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    }),
  ).isRequired,
  ratingCards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    }),
  ).isRequired,
  routeCalculation: PropTypes.shape().isRequired,
  productCode: PropTypes.string.isRequired,

  // router props
  query: PropTypes.shape().isRequired,
  path: PropTypes.string,
  infoText: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mediaCards: PropTypes.shape({}).isRequired,

  usingCustomTheme: PropTypes.bool.isRequired,
}
export default compose(withNabolag, withTheme, connect(mapStateToProps))(OverviewPage)
