import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import media from '../layout/media'
import { H2 } from '../../ui/Headings'

const StyledSection = styled.section`
  padding-left: ${props => props.theme.spacingLarge};
  padding-right: ${props => props.theme.spacingLarge};
  padding-bottom: ${props => props.theme.spacingLarge};

  ${media.small`
    padding-left: ${props => props.theme.spacing};
    padding-right: ${props => props.theme.spacing};
    padding-bottom: ${props => props.theme.spacing};
  `};
`

const StyledContent = styled.div`
  a {
    color: ${props => props.theme.brandColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-size: 16px;
    margin: 15px 0;
  }
`

const InfoText = ({ content }) => {
  const { title, text } = content
  return (
    <StyledSection>
      <H2>{title}</H2>
      <StyledContent dangerouslySetInnerHTML={{ __html: text }} />
    </StyledSection>
  )
}

InfoText.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default withTheme(InfoText)
