/*
 * Wrapper-class for all different cards.
 * Wraps the card and adds title and icon (if one exists)
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CardWrapper = styled.div`
  flex: 0 0 285px;
  justify-content: space-between;
`
const CardItem = styled.div`
  background-color: ${({ theme }) => theme.cardBGColor};
  text-align: left;
  margin: 0 15px 15px 0;
  height: 250px;
  position: relative;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 285px;
  max-width: 285px;
`

const CardContent = styled.div`
  position: relative;
  z-index: 3;
  flex: 0 1 100%;
`

const Figure = styled.figure`
  margin: 0;
`
const FigCaption = styled.figcaption`
  margin-top: 5px;
`
const ImgWrapper = styled.div`
  position: relative;
`
const Img = styled.img`
  max-width: 100%;
  width: 285px;
  height: 250px;
  object-fit: cover;
  overflow: hidden;
`

const CreditElement = styled.span`
  display: inline-block;
  position: absolute;
  background: #000;
  color: #fff;
  font-size: 12px;
  padding: 0 2px;
  right: 0px;
  bottom: 4px;
  opacity: 0.6;
`

const Image = props => {
  const { card } = props
  const { attr, dimensions, credit } = card
  const imgUrl =
    dimensions['600x400'] && dimensions['600x400'].url ? dimensions['600x400'].url : null
  if (!imgUrl) {
    return null
  }
  return (
    <CardWrapper>
      <CardItem>
        <CardContent>
          <Figure>
            <ImgWrapper>
              <Img src={imgUrl} alt={attr.Tittel} />
              {credit ? <CreditElement>&copy;&nbsp;{credit}</CreditElement> : null}
            </ImgWrapper>
            <FigCaption>{attr.Tittel}</FigCaption>
          </Figure>
        </CardContent>
      </CardItem>
    </CardWrapper>
  )
}

Image.propTypes = {
  card: PropTypes.shape({
    attr: PropTypes.shape({
      Tittel: PropTypes.string,
    }).isRequired,
    dimensions: PropTypes.shape({}).isRequired,
  }).isRequired,
}

export default Image
