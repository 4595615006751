import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import cardTypes from '../../models/CardTypes'

import { useTranslation } from '../../../i18n'
import Card from './components/Card'

const TravelList = styled.ul`
  list-style: none;
  padding: 0;
`
const TravelItem = styled.li`
  line-height: 28px;
  margin-bottom: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColorLight};
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

const LoadingText = styled.span`
  height: 18px;
  background: #e0e0e0;
  border-radius: 6px;
  display: inline-block;
  animation: blinker 2s linear infinite;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }
`

const TravelCard = ({ publicTravelTime, primaryTravelTime, address, loading, i18n }) => {
  const showNotFound = !loading && !publicTravelTime && !primaryTravelTime

  const { t } = useTranslation()

  return (
    <Card
      type={cardTypes.TRAVEL_ROUTE}
      title={t(`travel-time:card.title.${i18n}`, { address: address && address.label })}
    >
      <TravelList>
        {loading && !publicTravelTime ? (
          <>
            <TravelItem>
              <LoadingText width="220px" />
            </TravelItem>
            <TravelItem>
              <LoadingText width="200px" />
            </TravelItem>
          </>
        ) : null}
        {publicTravelTime ? (
          <TravelItem>
            {`${t('travel-time:fastestPublic')}:`}
            <strong>{publicTravelTime.duration}</strong>
          </TravelItem>
        ) : null}

        {loading && !primaryTravelTime ? (
          <>
            <TravelItem>
              <LoadingText width="220px" />
            </TravelItem>
            <TravelItem>
              <LoadingText width="200px" />
            </TravelItem>
          </>
        ) : null}
        {primaryTravelTime ? (
          <TravelItem>
            {`${t(`travel-time:fastestNonPublic.${primaryTravelTime.mode}`)}:`}
            <strong>{primaryTravelTime.duration}</strong>
          </TravelItem>
        ) : null}

        {showNotFound ? <TravelItem>{t('travel-time:not-found')}</TravelItem> : null}
      </TravelList>
    </Card>
  )
}

TravelCard.propTypes = {
  publicTravelTime: PropTypes.shape({
    duration: PropTypes.string,
  }),
  primaryTravelTime: PropTypes.shape({
    duration: PropTypes.string,
    mode: PropTypes.string,
  }),
  address: PropTypes.shape({
    label: PropTypes.string,
  }),
  loading: PropTypes.bool,
  i18n: PropTypes.string.isRequired,
}
export default TravelCard
