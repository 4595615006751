import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import storage from 'local-storage-fallback'
import { useRouter } from 'next/router'

import scrollTo from '../utils/scroll-to'
import getTravelTimeStorageKey from '../utils/getTravelTimeStorageKey'

import { useTranslation } from '../../i18n'
import { trackEvent } from '../utils/analytics'
import media from './layout/media'
import cardTypes from '../models/CardTypes'

import CardList from './CardList'
import MapConvertion from './map-convertion'
import { MapButton } from './map-convertion/style'
import { BaselineArrowForward } from './icons'
import ComparisonWidget from './ComparisonWidget/ComparisonContainer'
import BottomCategoryNav from './layout/BottomCategoryNav'
import Info from './info'
import TravelTimeButtonContainer from './travel-time-button-container'

import { getRouteCalculation, getShowRouteCalculation } from '../store/nabolag/selectors'

const MapWrapper = styled.div`
  background-color: ${({ theme }) => theme.contentBGColor};
  padding: 0 ${({ theme }) => `${theme.spacingLarge} ${theme.spacingLarge}`};
  ${media.small`
    padding: 0 ${({ theme }) => theme.spacing} ${({ theme }) => theme.spacing};
  `};
`

const trackMapClick = () => {
  trackEvent('Clicked show map frontpage', 'map', 'Vis adresse i kart')
}

const trackShowComparison = () => {
  trackEvent('Clicked show comparison frontpage', 'compare')
}

const trackShowTravelTime = () => {
  trackEvent('Clicked travel-time frontpage', 'address-travel-time frontpage')
}

const Overview = ({ factCards, nabolag, location, ratingCards, infoText, mediaCards }) => {
  const [hideTravelButton, setHideTravelButton] = useState(true)
  const [hasCheckedStorage, setHasCheckedStorage] = useState(false)
  const [cards, setCards] = useState([])
  const routeCalculation = useSelector(getRouteCalculation) || {}
  const showRouteCalculation = useSelector(getShowRouteCalculation)

  const router = useRouter()
  const { t } = useTranslation()

  useEffect(() => {
    setHasCheckedStorage(true)

    const storageKey = getTravelTimeStorageKey(routeCalculation.prefix)

    setCards(factCards)

    const routeCard = { type: cardTypes.TRAVEL_ROUTE, data: {} }

    // Only local addresses for profil.nabolag.no:
    const localAddresses = JSON.parse(storage.getItem(storageKey))
    if ((localAddresses || []).length > 0) {
      if (showRouteCalculation) {
        // Replace first card if it is an existing route-card
        if (factCards[0].type === cardTypes.TRAVEL_ROUTE) {
          setCards([routeCard, ...factCards.slice(1)])
        } else {
          setCards([routeCard, ...factCards])
        }
      }

      setHideTravelButton(true)
    } else {
      if (factCards[0].type === cardTypes.TRAVEL_ROUTE) {
        // Remove first card
        setCards(factCards.slice(1))
      }

      setHideTravelButton(false)
    }
  }, [])

  useEffect(() => {
    if (router.query.scrollToId && cards.length > 0) {
      scrollTo(router.query.scrollToId)
    }
  }, [cards])

  const showTravelButton = showRouteCalculation && !hideTravelButton && hasCheckedStorage
  const showCompareTop = hasCheckedStorage && !showTravelButton
  const showCompareBottom = hasCheckedStorage && showTravelButton

  return (
    <>
      <CardList
        title={t('common:factCards')}
        subtitle={nabolag.header}
        subHeader={nabolag.subHeader}
        cards={cards}
        nabolag={nabolag}
        hideBorder
        removeTopMargin
      />
      <Info infoTexts={infoText} mediaCards={mediaCards} nabolag={nabolag} />
      {showTravelButton ? (
        <TravelTimeButtonContainer nabolag={nabolag} onTrackShow={trackShowTravelTime} />
      ) : null}
      {showCompareTop ? (
        <ComparisonWidget nabolag={nabolag} onTrackShow={trackShowComparison} />
      ) : null}
      <CardList
        title={t('common:whatDoesTheNeighborsSay')}
        subtitle={nabolag.neighborhood ? `${t('neighbourhood')}: ${nabolag.neighborhood.name}` : ''}
        type="rating"
        cards={ratingCards}
        nabolag={nabolag}
        id="ratings"
      />
      {showCompareBottom ? (
        <ComparisonWidget nabolag={nabolag} onTrackShow={trackShowComparison} />
      ) : null}
      <MapWrapper>
        <MapConvertion
          lat={location.lat}
          long={location.long}
          onClick={trackMapClick}
          overlay={() => (
            <MapButton role="presentation">
              {t('common:mapButton.buttonText')} <BaselineArrowForward size={36} />
            </MapButton>
          )}
        />
      </MapWrapper>
      <BottomCategoryNav source="forsiden" />
    </>
  )
}

Overview.propTypes = {
  nabolag: PropTypes.shape().isRequired,
  factCards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ratingCards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  infoText: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mediaCards: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
  }).isRequired,
}

export default Overview
