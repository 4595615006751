import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'

import Card from './components/Card'
import { withTranslation } from '../../../i18n'

const QuoteFrom = styled.p`
  ${({ theme }) =>
    theme &&
    `
  margin-left: ${theme.spacing};
  margin-right: ${theme.spacing};
  color: ${theme.fontColorLight};
`};
`

const QuoteCard = props => {
  const { card, t } = props
  const { title } = card.data
  return (
    <Card type={card.data.type || card.type} title={title}>
      <QuoteFrom>{t('common:cards.QUOTE_FROM', { from: card.data.name })}</QuoteFrom>
    </Card>
  )
}

QuoteCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  t: PropTypes.func.isRequired,
}
export default compose(withTranslation(['common']))(QuoteCard)
