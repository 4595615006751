/*
 * Wrapper-class for all different cards.
 * Wraps the card and adds title and icon (if one exists)
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { useSelector } from 'react-redux'
import Link from 'next/link'

import cardTypes, { cartTypeCategory, cardTypeLinkText } from '../../../models/CardTypes'
import { getProductCode } from '../../../store/nabolag/selectors'
import { trackEvent } from '../../../utils/analytics'
import InternalLink from '../../../utils/internal-link'

import CardIcon from './CardIcon'
import { withTranslation } from '../../../../i18n'

const CardWrapper = styled.div`
  flex: 0 0 280px;
  justify-content: space-between;
`
const CardItem = styled.div`
  background-color: ${({ theme }) => theme.cardBGColor};
  text-align: left;
  margin: 15px;
  margin-left: 0px;
  box-shadow: ${({theme}) => theme.themeShadow || 
    `0 0 2px rgba(0, 0, 0, 0.12), 
    0 2px 2px rgba(0, 0, 0, 0.24), 
    0 0 8px rgba(0, 0, 0, 0.12)`
};
  border-radius: 4px;
  height: 360px;
  position: relative;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 280px;
  max-width: 280px;
`
const CardHeader = styled.div`
  flex: 1 0 auto;
  height: auto;
  min-height: 48px;
  width: 100%;
  align-self: flex-start;
`
const CardTitle = styled.h3`
  ${({ theme }) =>
    theme &&
    `
  padding: ${theme.spacing};
  padding-bottom: 0;
  `};
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  height: auto;
  min-height: 48px;
  overflow: hidden;
  ${props =>
    props.type === 'QUOTE' &&
    `
      &:before {
        content: "\\00ab";
      }
      &:after {
        content: "\\00bb";
      }
    `};
`
const CardContent = styled.div`
  position: relative;
  z-index: 3;
  flex: 0 1 100%;
`
const MoreAboutLink = styled.a`
  color: ${({ theme }) => theme.linkColor};
  font-size: 18px;
  text-decoration: ${props => (props.theme.linkDecoration === 'none' ? 'none' : 'underline')};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.linkColorHover};
    text-decoration: ${props => (props.theme.linkDecoration === 'none' ? 'underline' : 'none')};
  }
`
const Card = ({ type, children, title, fullHeight, additionalLinkParams, t }) => {
  const productCode = useSelector(state => getProductCode(state))
  const showLink = type !== cardTypes.SUN_CURVE || productCode !== 'fritid' // "bomiljø" is not a part of fritid
  const route = cartTypeCategory[type] ? cartTypeCategory[type].toLowerCase() : ''
  const trackingLabel = cartTypeCategory[type]
    ? t(`common:moreAbout.${cartTypeCategory[type].toLowerCase()}`)
    : type
  const linkText = cartTypeCategory[type]
    ? t(`common:moreAbout.${cardTypeLinkText[type].toLowerCase()}`)
    : type
  return (
    <CardWrapper>
      <CardItem>
        <CardHeader>
          <CardTitle type={type}>{title || 'title'}</CardTitle>
        </CardHeader>
        <CardContent>{children}</CardContent>
        <CardIcon type={type} fade={fullHeight} />
      </CardItem>
      {showLink ? (
        <InternalLink route={route} params={{ ...additionalLinkParams }} passHref>
          <MoreAboutLink
            onClick={() => {
              trackEvent('Clicked read more card frontpage', 'cardlist', trackingLabel)
            }}
          >
            {linkText}
          </MoreAboutLink>
        </InternalLink>
      ) : null}
    </CardWrapper>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  fullHeight: PropTypes.bool,
  additionalLinkParams: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

Card.defaultProps = { fullHeight: false, additionalLinkParams: {} }

export default compose(withTranslation(['common']))(Card)
